import { IMember } from '../models/member.model';

// Icons
import twitter from '../assets/logos/twitter-circle.svg';
import linkedin from '../assets/logos/linkedin-circle.svg';
import github from '../assets/logos/github.svg';

const MEMBERS: IMember[] = [
  {
    name: 'Danitza Bonilla',
    img: 'https://avatars2.githubusercontent.com/u/64857316',
    profession: 'Frontend Developer',
    description:
      'Disfruto crear, y ver como lo que pienso se hace realidad. Adoro contribuir con el conocimiento que voy aprendiendo y soy muy feliz porque disfruto lo hago.',
    socialNetwork: [
      {
        id: 'linkedin',
        icon: linkedin,
        url: 'https://www.linkedin.com/in/danitzabonilla/',
      },
      {
        id: 'github',
        icon: github,
        url: 'https://github.com/Danitzab',
      },
    ],
  },
  {
    name: 'Leonardo Solano',
    img: 'https://avatars3.githubusercontent.com/u/19717395',
    profession: 'Full Stack Developer',
    description:
      'Empecé a construir <strong>Blucoding</strong> cuando empecé a entrenar a mi esposa en la programación y descubrí que el conocimiento cuando se comparte traspasa las barreras.',
    socialNetwork: [
      {
        id: 'twitter',
        icon: twitter,
        url: 'https://twitter.com/lsolano2707',
      },
      {
        id: 'linkedin',
        icon: linkedin,
        url: 'https://www.linkedin.com/in/lsolano2707/',
      },
      {
        id: 'github',
        icon: github,
        url: 'https://github.com/lsolano2707',
      },
    ],
  },
];

export const getTeam = (): IMember[] => {
  return MEMBERS;
};
