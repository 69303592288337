import React from 'react';

import './banner.scss';

// Images
import banner from '@assets/images/working.svg';
import waves from '@assets/images/banner-wave.svg';

const Banner = () => {
  return (
    <div className="banner w-full mb-1" style={{background: `url(${waves}) no-repeat`}}>
      <div className="container mx-auto px-4 mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <h1 className="col-span-1 self-center font-bold text-center sm:text-5xl mb-12">¿List@ para un entrenamiento?</h1>
          <img className="col-span-1 self-center" src={banner} alt="banner" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
