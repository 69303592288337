// import { IMember } from '../models/member.model';

// Models
// import { IDoc } from '../models/doc.model';

// Icon
import iconsIcon from '@assets/icons/paint-palette.svg';
import imagesIcon from '@assets/icons/image.svg';
import videosIcon from '@assets/icons/movie-reel.svg';
import gamesIcon from '@assets/icons/video-game.svg';
import rocketIcon from '@assets/icons/rocket.svg';
import achievementIcon from '@assets/icons/achievement.svg';
import onlineLearningIcon from '@assets/icons/online-learning.svg';
// import movieReelIcon from '@assets/icons/movie-reel.svg';

const RESOURCES = [
  {
    path: '/icons',
    title: 'Iconos',
    logo: iconsIcon,
  },
  {
    path: '/images',
    title: 'Imágenes',
    logo: imagesIcon,
  },
  {
    path: '/videos',
    title: 'Vídeos',
    logo: videosIcon,
  },
  {
    path: '/games',
    title: 'Juegos para Aprender',
    logo: gamesIcon,
  },
  {
    path: '/challenges',
    title: 'Desafíos',
    logo: achievementIcon,
  },
  {
    path: '/deploy-websites',
    title: 'Sitios para Alojar Aplicaciones Web',
    logo: rocketIcon,
  },
  {
    path: '/courses',
    title: 'Plataformas Educativas Online',
    logo: onlineLearningIcon,
  },
];

export const getResources = () => {
  return RESOURCES;
};
