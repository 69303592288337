import React from 'react';
import { TeamCard } from './teamCard';
import { getTeam } from '../services/team.service';
import { IMember } from '../models/member.model';

const TeamGrid = () => {
  return (
    <div className='flex flex-wrap justify-center my-4 space-x-0 sm:space-x-8'>
      {getTeam().map((element: IMember, i: number) => (
        <TeamCard key={i} {...element} />
      ))}
    </div>
  );
};

export default TeamGrid;
