import React, { useEffect, useState } from 'react';
import './index.scss';

// Components
import SEO from '@components/seo';
import Banner from '@components/banner';
import TeamGrid from '@components/teamGrid';
import CardGrid from '../components/cardGrid';

// Services
import { getDocs } from '@services/document.service';
import { getResources } from '@services/resource.service';

const IndexPage = () => {
  const [docs, setDocs] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    setDocs(getDocs());
    setResources(getResources());
  }, []);

  return (
    <div className="home">
      <SEO title="Home" />
      <Banner />

      <section className="py-12 blogs">
        <div className="container mx-auto px-4 mt-5">
          <h1 className="font-medium text-center mb-8">
            ¿Qué quieres aprender?
          </h1>
          <CardGrid elements={docs} cols={4} cssClasses={'text-center'} />
        </div>
      </section>

      <section className="py-12 resources">
        <div className="container mx-auto px-4 mt-5">
          <h1 className="font-medium text-center mb-8">Recursos</h1>
          <CardGrid
            path="resources"
            elements={resources}
            cols={4}
            cssClasses={'text-center'}
          />
        </div>
      </section>

      <section className="py-12 about-us">
        <div className="container mx-auto px-4 mt-5">
          <h1 className="font-medium text-center mb-8">¿Quiénes Somos?</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
            dicta nihil rerum adipisci animi officia, ratione tempora aut
            recusandae ipsa minima doloribus praesentium quibusdam alias
            repudiandae eveniet? Eos, nam incidunt.
          </p>
        </div>
      </section>

      <section className="py-12 team">
        <div className="container mx-auto px-4 mt-5">
          <h1 className="font-medium text-center mb-8">Nuestro Equipo</h1>
          <TeamGrid />
        </div>
      </section>
    </div>
  );
};

export default IndexPage;
