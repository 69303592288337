import React from 'react';

// Models
import { IMember } from '../models/member.model';
import { ISocialNetwork } from '../models/social-network.model';

export const TeamCard = (props: IMember) => {
  return (
    // <div className='flex flex-col items-center justify-center shadow rounded-lg bg-white p-4 w-full sm:w-2/7'>
    <div className='flex flex-col items-center justify-center border border-solid border-gray-200 rounded-lg bg-white my-2 p-4 w-full sm:w-2/7'>
      <div className='inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40'>
        <img src={props.img} alt={props.name} className='h-full w-full' />
      </div>

      <h2 className='mt-4 mb-0 font-bold text-xl'>{props.name}</h2>
      <small className='mb-2 text-sm  text-gray-600 font-medium'>{props.profession}</small>

      <p className='text-sm text-justify mt-3' dangerouslySetInnerHTML={{ __html: props.description }}></p>

      <div className='self-end'>
        <ul className='flex flex-row mt-4 space-x-2 list-none pl-0'>
          {props.socialNetwork?.map((element: ISocialNetwork) => (
            <li key={element.id}>
              <a href={element.url} target='_blank' rel='noopener noreferrer' className='flex items-center justify-center h-8 w-8'>
                <img src={element.icon} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
